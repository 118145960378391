const questions = [
  // 1 Слайд \\ Перу [1]
  {
    questionText:
      "Peru is a mountainous country where the cultures of indigenous people and European settlers interlace to form an original pattern.",
    answerOptions: [
      {
        answerText:
          "Peru is the third largest country on the South American continent after Brazil and Argentina.",
        answerResult: "Correct! This country has an area of 1,285,000 km2",
        result: true,
      },
      {
        answerText:
          "In Peru, they managed to tame wild llamas only in the middle of the XX century.",
        answerResult:
          "No way. Llamas became domestic animals more than 3,000 years ago.",
        result: false,
      },
      {
        answerText:
          "A Peruvian traditional headdress – a knitted hat with earflaps – is called a quipu.",
        answerResult:
          "No, it's not. A quipu is an ancient method of knot writing. And the hat is called a chullo.",
        result: false,
      },
      {
        answerText:
          "In the Nazca Desert, there are mysterious pyramids – the artefacts of an ancient civilization.",
        answerResult:
          "Undoubtedly, the ancient Peruvians left their mark in the Nazca Desert. But it's not the pyramids, it's the geoglyphs – giant drawings on the ground.",
        result: false,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд \\ Аргентина [4]

  {
    questionText:
      "Travelling around Argentina, you can see wild monkeys in the northern jungle and penguins on the cold southern coast.",
    answerOptions: [
      {
        answerText:
          "The name of the capital of Argentina means «Beautiful flowers» in Spanish.",
        answerResult:
          "The capital of Argentina is Buenos Aires, which means «Fair winds»",
        result: false,
      },
      {
        answerText:
          "Argentina national football team became a world champion more often than the other teams.",
        answerResult:
          "Unfortunately for the Argentines, their national team won the World Cup trophy only three times. Italy and Germany became champions four times, and Brazil – five times.",
        result: false,
      },
      {
        answerText: "Most Argentines are vegetarians.",
        answerResult:
          "Just the opposite. Argentina ranks first on the planet in meat consumption. Its entire national cuisine is based on it.",
        result: false,
      },
      {
        answerText:
          "The Argentines drink mate, their traditional tea, through a straw from a special container called a calabash.",
        answerResult:
          "Right you are! And a straw with a strainer is called a bombilla.",
        result: true,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд \\ Вьетнам [1]
  {
    questionText:
      "This country of jungle and big cities has stretched from north to south on the coast of the South China Sea. What do we know about it? Let's check!",
    answerOptions: [
      {
        answerText:
          "On the south of Vietnam, there is a small desert with dunes.",
        answerResult:
          "That's right. You can ride a quad bike on the barchanes, or you can just slide down the dunes.",
        result: true,
      },
      {
        answerText: "Vietnam ranks first in the world in coffee production.",
        answerResult:
          "Surprisingly, only Brazil managed to outrank Vietnam in coffee production. So, this country takes the honorary second place.",
        result: false,
      },
      {
        answerText:
          "Vietnamese money – dongs – is made of fabric for greater durability.",
        answerResult:
          "Dongs really can't be torn, soiled or get wet. But they are made of plastic.",
        result: false,
      },
      {
        answerText:
          "Pho, a famous Vietnamese noodle soup, came to us from the ancient times.",
        answerResult:
          "History says otherwise. The Vietnamese started eating beef, which is necessary for preparing a broth for noddles, in the XIX century, following the example of the Europeans. Before that time, the cattle were used only for plowing and carrying baggage.",
        result: false,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд \\ Малайзия [3]
  {
    questionText:
      "Our neighbours have a lot to surprise us. No wonder Malaysia is one of the top ten countries visited by tourists. Here are some more statements about this country. But only one of them is true.",
    answerOptions: [
      {
        answerText:
          "The border between two states runs through the Kalimantan island.",
        answerResult:
          "Actually, it's the border between three states. Parts of this island belong not only to Indonesia and Malaysia, but also to Brunei.",
        result: false,
      },
      {
        answerText: "About 50 varieties of apples grow in Malaysia.",
        answerResult:
          "Despite the fact that the most exotic fruits can be found in Malaysia, ordinary apples don't grow here. They are always imported.",
        result: false,
      },
      {
        answerText: "You will be fined for riding an elephant around the city.",
        answerResult:
          "That's true. Besides, it's much more convenient to ride a car or a bike, there are a lot of them in Malaysia.",
        result: true,
      },
      {
        answerText:
          "The world's smallest bears, biruangs, live in Malaysia. They are the size of an average dog.",
        answerResult:
          "Well, not really. A biruang is actually the smallest bear, but it can be up to 1.5 meters tall, and weigh up to 65 kilos.",
        result: false,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд \\ Бразилия [2]
  {
    questionText:
      "Samba, anacondas, capoeira – all of this is present in Brazil, a country covered with rainforests. And what else is it famous for? Choose the correct statement.",
    answerOptions: [
      {
        answerText:
          "The capital of Brazil is São Paulo. The architect Lúcio Costa designed this city in the form of a butterfly or a flying bird.",
        answerResult:
          "Indeed, the capital resembles a bird or even a plane. But this city is called Brasília.",
        result: false,
      },
      {
        answerText:
          "There is so much sugar cane in Brazil that it is even used in production of fuel capable of replacing petroleum products.",
        answerResult:
          "That's true! Brazil is the world leader in growing sugar cane. And oranges as well.",
        result: true,
      },
      {
        answerText: "The famous Brazilian carnival lasts for one month.",
        answerResult:
          "No matter how much the locals want to prolong this holiday, the carnival lasts for just five days. And after it, comes the Lent.",
        result: false,
      },
      {
        answerText:
          "We already know that the Brazilians are excellent footballers. They surely are, since this game is mentioned even in their national anthem.",
        answerResult:
          "No, that's too much. The Brazilian national anthem speaks mainly of love to their motherland, their glorious past and bright future.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 7 слайд \\ Индонезия [3]
  {
    questionText:
      "Well, this one should be easy for you. Any Indonesian school kid can tell which of these four statements is true, and which of them we have made up.",
    answerOptions: [
      {
        answerText:
          "There are 9 UNESCO World Heritage Sites in Indonesia. All of them are natural, like the Komodo National Park.",
        answerResult:
          "Only half of these sites are natural. The rest of them are created by humans, for example, Borobudur and Prambanan temple compounds.",
        result: false,
      },
      {
        answerText: "The Indonesians love sleeping",
        answerResult:
          "Maybe it's true, but most of them get up very early, often before 5:00 AM. In the morning, they can pray, get to work before the traffic jams, or go to study.",
        result: false,
      },
      {
        answerText: "Indonesia is a country of motor taxi",
        answerResult:
          "It sure is. Due to the heavy traffic, this maneuverable means of transport is very popular here.",
        result: true,
      },
      {
        answerText:
          "Cats seem to be everywhere in Indonesia, but their population is actually no more than 1,000,000.",
        answerResult:
          "One million cats for such a large country is too little. There are more than 30,000,000 cats here. And this is without the wooden ones from Bali island.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },

  // 8 слайд \\ Мексика [2]
  {
    questionText:
      "When we talk about Mexico, we surely imagine people wearing sombreros and the desert full of cacti. But to what extent are these stereotypes true? Let's check!",
    answerOptions: [
      {
        answerText:
          "There is no taxi in the capital of Mexico, but the public transport is developed.",
        answerResult:
          "Just the opposite. It is Mexico City that ranks first in the world in terms of the amount of taxi cars. There are more than 100,000 of them.",
        result: false,
      },
      {
        answerText: "Popcorn was invented in Mexico more than 4,000 years ago.",
        answerResult:
          "That's right. The indigenous people of Mexico heated a special kind of corn and used the opened weeds for eating or decorating.",
        result: true,
      },
      {
        answerText:
          "One of Mexico's symbols, the Chihuahua dogs, was imported by the Spanish conquistadors, who used this breed to catch rats on ships.",
        answerResult:
          "Chihuahuas are 100% Mexican. These brave babies are descended from an extinct breed of the Techichi that was considered sacred by the Mexican tribes.",
        result: false,
      },
      {
        answerText: "In Mexico, the cacti are used only to prepare tequila.",
        answerResult:
          "Of course not. Different kinds of cacti are used for eating, preparing medicines, shampoos, dyes and even fabric.",
        result: false,
      },
    ],
    image: "images/07.webp",
  },
];

export default questions;
